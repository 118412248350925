import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FreezeAppAlertsApiService } from '../freeze-app-alerts-api.service';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FreezeAppAlertsInterface, UserIdNameRoleInterface } from '../freeze-app-alerts-interface';

@Component({
  selector: 'app-view-freeze-app-alert',
  templateUrl: './view-freeze-app-alert.component.html',
  styleUrls: ['./view-freeze-app-alert.component.scss']
})
export class ViewFreezeAppAlertComponent {

  freezeAppAlertId: string

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit: number = 10;
  filterValue: string = ''

  dataSource = new MatTableDataSource<UserIdNameRoleInterface[]>();
  displayedColumns = ['userName', 'mobileNumber'];

  receiverUsers: UserIdNameRoleInterface[] = []
  freezeAppAlertsObjs: FreezeAppAlertsInterface[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public freezeAppAlertsApiService: FreezeAppAlertsApiService,
    private toastr: ToastrService,
  ) {
    this.freezeAppAlertId = data.freezeAppAlertId
  }

  ngOnInit() {
    this.getCustomNotificationById();
  }

  getCustomNotificationById() {
    let queryData: Object = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_active_status: null,
      search_term: this.filterValue || null
    };

    this.freezeAppAlertsApiService.geFreezeAppAlertById(
      this.freezeAppAlertId, queryData
    ).subscribe((resp) => {
      if (resp.status === "success") {
        this.freezeAppAlertsObjs = [resp.data.freeze_app_alert];
        this.dataSource.data = resp.data.freeze_app_alert.freeze_app_alert_users.users;
        this.totalCount = resp.data.freeze_app_alert.freeze_app_alert_users.total_users_count;
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getCustomNotificationById();
  }






}
