<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class=" mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{pageTitle}}</h2>
        </div>

        <div class="card" [formGroup]="createFreezeAppAlertForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Freeze App Alert
                </h4>
                <div class="row mr-3 ml-3">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Title
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="fontsSizeStyle"> (Maxlength(300))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="title" style="background: #FFFFFF;" matInput placeholder="Enter Title"
                                    formControlName="title" required  maxlength="300" (keypress)="handleStartingSpaceTextInput($event, createFreezeAppAlertForm.get('title').value)">
                                    <mat-error *ngIf="createFreezeAppAlertForm.get('title').hasError('required')">
                                        Title is required
                                    </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Description
                            <span style="font-weight: lighter;" class="fontsSizeStyle"> (Maxlength(300))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="description" style="background: #FFFFFF;" matInput maxlength="300"
                                   placeholder="Enter Description" formControlName="description"
                                   (keypress)="handleStartingSpaceTextInput($event, createFreezeAppAlertForm.get('description').value)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="!redirectTypeNotUpdatable">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="fw-bold fieldName">
                                Redirect Type
                                <span class="text-danger">*</span>
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <mat-select id="redirect_type" name="Redirect Type" required [placeholder]="'Redirect Type'" formControlName="redirectType" (ngModelChange)="updatedRedirectType()">
                                        <mat-option *ngFor="let redirectType of redirectTypes" [value]="redirectType.value">{{redirectType.viewValue}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="createFreezeAppAlertForm.get('redirectType').hasError('required')">
                                        Redirect Type is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4" *ngIf="showRedirectUrl">
                            <div class="fw-bold fieldName">
                                Redirect Url
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="redirect_url" style="background: #FFFFFF;"  matInput
                                       placeholder="Enter Redirect URL" formControlName="redirectUrl"
                                       (keypress)="handleStartingSpaceTextInput($event, createFreezeAppAlertForm.get('redirectUrl').value)">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                    Branch
                                </div>
                                <div class="" >
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                                      <mat-select [placeholder]="'All Branches'" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBranchIds" (ngModelChange)="getUsers()" multiple>
                                        <mat-checkbox class="" [checked]="allBranchesChecked" [indeterminate]="someBranchesChecked()" (change)="setAllBranchesChecked($event)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let branch of branchIdNameList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                   
                                  </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                   Role
                                </div>
                                <div class="" >
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                                      <mat-select [placeholder]="'All Roles'" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedroleIds" (ngModelChange)="getUsers()" multiple>
                                        <mat-checkbox class="" [checked]="allRolesChecked" [indeterminate]="someRolesChecked()" (change)="setAllRolesChecked($event)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let role of roleIdNameList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                    User
                                </div>
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
                                      <mat-select [placeholder]="'All Users'" [ngModelOptions]="{standalone: true}" [(ngModel)]="receiverUserIds" multiple >
                                        <div style="display: flex;justify-content: flex-start; align-items: center;">
                                            <i class="material-icons mr-1">search</i>
                                            <input matInput (keyup)="applyFilterOnUsers($event.target.value)">
                                        </div>
                                        <mat-checkbox class="" [checked]="allUsersChecked" [indeterminate]="someUsersChecked()" (change)="setAllUsersChecked($event)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let user of filteredUserIdNameList" [value]="user.id">{{user.full_name | titlecase }}<span>({{user.mobile_number}})</span></mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                    Submit Button Text
                                    <span style="font-weight: lighter;" class="fontsSizeStyle"> (Maxlength(100))</span>
                                </div>
                                <div class="">
                                    <mat-form-field class="w-full">
                                        <input id="submitButtonText" style="background: #FFFFFF;" matInput maxlength="100"
                                           placeholder="Enter Submit Button Text" formControlName="submitButtonText"
                                           (keypress)="handleStartingSpaceTextInput($event, createFreezeAppAlertForm.get('submitButtonText').value)">
                                    </mat-form-field>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="m-2 btnposition">
        <div class="bottomBtns mb-3 row">
          <button class="ml-4 addBtn" *ngIf="!isUpdate" (click)="reset()"mat-flat-button>
            <span class="ml-2 mr-1 txt">Reset</span>
          </button>
          <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
         (click)="createFreezeAppAlert()"  *ngIf="!isUpdate"  [disabled]="!isFreezeAppAlertValid()" [ngClass]="{'btn-clr': isFreezeAppAlertValid()}">
            <span class="ml-2 mr-1 txt" style="color: white">Create </span>
          </button>
        </div>
      </div>
</div>