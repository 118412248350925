<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class=" mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{pageTitle}}</h2>
        </div>

        <div class="card" [formGroup]="createBloodWorkTestForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Create Blood Work Test
                </h4>
                <div class="row mr-3 ml-3">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Test Name
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="testName" style="background: #FFFFFF;" matInput
                                   placeholder="Enter name" formControlName="testName">
                                <mat-error *ngIf="createBloodWorkTestForm.get('testName').hasError('required')">
                                    Name is required
                                </mat-error>
                                <mat-error *ngIf="createBloodWorkTestForm.get('testName').hasError('maxlength')">
                                    Length of name should be less than or equals to 100
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Description
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(300))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="description" style="background: #FFFFFF;" matInput
                                   placeholder="Enter description" formControlName="description">
                                <mat-error *ngIf="createBloodWorkTestForm.get('description').hasError('required')">
                                    Description is required
                                </mat-error>
                                <mat-error *ngIf="createBloodWorkTestForm.get('description').hasError('maxlength')">
                                    Length of description should be less than or equals to 300
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Measurement Units
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(20))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="description" style="background: #FFFFFF;" matInput
                                   placeholder="Enter units" formControlName="measurementUnits">
                                <mat-error *ngIf="createBloodWorkTestForm.get('measurementUnits').hasError('required')">
                                    Units are required
                                </mat-error>
                                <mat-error *ngIf="createBloodWorkTestForm.get('measurementUnits').hasError('maxlength')">
                                    Length of measurement units should be less than or equals to 20
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-4 mr-2 ml-2" [formGroup]="createBloodWorkTestRangeForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="">
                <h4 style="color: #00FF00;">
                    Create Test Ranges
                </h4>
                <mat-hint class="hintStyle">**Hint: Atleast one range should be added</mat-hint>
                <div class="row mr-3 ml-3">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Range Min Value
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="minValue" style="background: #FFFFFF;" matInput type="number" step="0.01"
                                   placeholder="Enter value" formControlName="rangeMin">
                                <mat-error *ngIf="createBloodWorkTestRangeForm.get('rangeMin').hasError('required')">
                                    Value is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Range Max Value
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="maxValue" style="background: #FFFFFF;" matInput type="number" step="0.01"
                                   placeholder="Enter value" formControlName="rangeMax">
                                <mat-error *ngIf="createBloodWorkTestRangeForm.get('rangeMax').hasError('required')">
                                    Value is required
                                </mat-error>
                                <!-- <mat-error *ngIf="createBloodWorkTestRangeForm.get('rangeMax').hasError('pattern')">
                                    Value should be valid number
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Range Meaning
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(300))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="meaning" style="background: #FFFFFF;" matInput
                                   placeholder="Enter meaning" formControlName="rangeMeaning">
                                <mat-error *ngIf="createBloodWorkTestRangeForm.get('rangeMeaning').hasError('required')">
                                    Range Meaning is required
                                </mat-error>
                                <mat-error *ngIf="createBloodWorkTestRangeForm.get('rangeMeaning').hasError('maxlength')">
                                    Length of range meaning should be less than or equals to 300
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="mt-6">
                            <div class="d-flex justify-content-center" *ngIf="isClashingRangesGiven">
                                <span class="text-danger">{{ errRangesMessage }}</span>
                            </div>
                            <div class=" d-flex justify-content-end">
                                <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="addTestRange()"
                                [ngClass]="{'btn-clr': createBloodWorkTestRangeForm.valid}"
                                [disabled]="createBloodWorkTestRangeForm.invalid"
                                >
                                    <span class="ml-2 mr-1 txt" style="color: white">Add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 mt-6 d-flex flex-column  m-auto overflow-x-scroll"
                    style="max-height:450px; overflow-y:scroll;">
                    <table class="table" style="width: 90%">
                        <thead class="thead-dark"
                            style="background: #000; color: #fff;  position: sticky; top: 0;">
                            <tr>
                                <th scope="col">Range Min Value</th>
                                <th scope="col">Range Max Value</th>
                                <th scope="col">Range Meaning</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tRange of testRanges">
                                <th scope="row">{{tRange.rangeMinValue}}</th>
                                <th scope="row">{{tRange.rangeMaxValue}}</th>
                                <th scope="row">{{tRange.rangeMeaning}}</th>
                                <td>
                                    <button mat-icon-button (click)="deleteTestRange(tRange)">
                                        <mat-icon style="color: red" svgIcon="mat_outline:delete"></mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>

        </div>


    </div>

    <div class="m-2 btnposition">
        <div class="bottomBtns mb-3 row">
          <button class="ml-4 addBtn" (click)="reset()" mat-flat-button>
            <span class="ml-2 mr-1 txt">Reset</span>
          </button>
          <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
         (click)="createBloodWorkTestAPI()"  [disabled]="!isBloodWorkTestDataValid()" [ngClass]="{'btn-clr': isBloodWorkTestDataValid()}">
            <span class="ml-2 mr-1 txt" style="color: white">Create </span>
          </button>
        </div>
      </div>
</div>