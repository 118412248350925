import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-blood-work-test',
  templateUrl: './view-blood-work-test.component.html',
  styleUrls: ['./view-blood-work-test.component.scss']
})
export class ViewBloodWorkTestComponent {

  bloodWorkTestInfo = [];
  testRanges = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
      this.bloodWorkTestInfo = this.data.bloodWorkTestInfo;
      this.testRanges = this.data.bloodWorkTestInfo[0].ranges
    }



}
