import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'app/api-services/api.service';
import { BranchIdNameInterface, RoleIdNameInterface, UserIdNameInterface } from 'app/pages/steel-banners/steel-banners.interface';

@Component({
  selector: 'app-create-blood-work-stat',
  templateUrl: './create-blood-work-stat.component.html',
  styleUrls: ['./create-blood-work-stat.component.scss']
})
export class CreateBloodWorkStatComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput
  
  pageTitle: string = "Create Blood Work Stat"

  public createBloodWorkForm: FormGroup;

  branchIdNameList: BranchIdNameInterface[] = []
  selectedBranchIds: string[] = []

  roleIdNameList: RoleIdNameInterface[] = []
  selectedroleIds: string[] = []

  userIdNameList: UserIdNameInterface[] = []
  filteredUserIdNameList: UserIdNameInterface[] = []
  selectedUserId: string = null

  testIdNames = []

  back(){
    this.router.navigateByUrl('Admin/blood-work-stats');
  }

  constructor(
      private _formBuilder: FormBuilder,
      private router: Router, 
      public apiService: ApiService,
      private toastr: ToastrService,
  ){
      // For now we are not implementing anythin related to edit, we will do so later
      this.buildCreateBloodWorkForm();
  }
  
  ngOnInit() {
    this.getBloodWorkTests();
    this.getRoles();
    this.getBranches();
    this.getUsers();
  }
  
  public buildCreateBloodWorkForm() {
    this.createBloodWorkForm = this._formBuilder.group({
      bloodWorkTest: new FormControl(null, [Validators.required]),
      value: new FormControl(null, [Validators.required]),
    });
  }

  applyFilterOnUsers(searchValue) {
    this.filteredUserIdNameList = this.userIdNameList.filter(optionObj => 
      optionObj.full_name.toLowerCase().includes(searchValue.toLowerCase()) || this.selectedUserId == optionObj.id
    )
  }

  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return { "id": role.id, "name": role.name }
        });
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name, "mobile_number": user.mobile_number,
          }
        });
        this.filteredUserIdNameList = this.userIdNameList.map(e => e);
      }
    })
  }

  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // Resetting details
  resetCheckedUsers() {
    this.selectedUserId = null
  }

  reset(){
    this.buildCreateBloodWorkForm();

    this.allBranchesChecked = false;
    this.selectedBranchIds = [];

    this.allRolesChecked = false;
    this.selectedroleIds = [];

    this.selectedUserId = null;
  }

  isBloodWorkDataValid(){
    return this.createBloodWorkForm.valid && this.selectedUserId
  }

  getBloodWorkTests(){
    let queryData = {
      filter_by_active_status: true,
    }
    this.apiService.getBloodWorkTestIdNames(queryData=queryData).subscribe((resp)=>{
      if(resp.status == "success"){
        this.testIdNames = resp.data.blood_work_tests;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  createBloodWorkAPI(){
    if(this.createBloodWorkForm.valid && this.selectedUserId){
      let payload = {
        user_id: this.selectedUserId,
        test_value: this.createBloodWorkForm.controls.value.value,
        bloodwork_test_id: this.createBloodWorkForm.controls.bloodWorkTest.value,
      }
      this.apiService.createBloodWorkStat(payload).subscribe((resp)=>{
        if(resp.status == "success"){
          this.router.navigateByUrl('Admin/blood-work-stats');
        }
      },
      (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }
}
