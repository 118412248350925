import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { FreezeAppAlertsInterface } from '../freeze-app-alerts-interface';
import { FreezeAppAlertsApiService } from '../freeze-app-alerts-api.service';
import { ViewFreezeAppAlertComponent } from '../view-freeze-app-alert/view-freeze-app-alert.component';

@Component({
  selector: 'app-freeze-app-alerts-list',
  templateUrl: './freeze-app-alerts-list.component.html',
  styleUrls: ['./freeze-app-alerts-list.component.scss']
})
export class FreezeAppAlertsListComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<FreezeAppAlertsInterface>();

  displayedColumns = ['title', 'description', 'redirectType', 'redirectUrl', 'createdBy', 'createdAt', 'Actions'];

  freezeAppAlertsList: FreezeAppAlertsInterface[] = []

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit: number = 10;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToFreezeAppAlertCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_FREEZE_APP_ALERTS) > -1;
  isUserAllowedToFreezeAppAlertView = this.userAllowedFeatures.indexOf(featureConstants.VIEW_FREEZE_APP_ALERTS) > -1;

  filterValue: string = "";

  filterByActiveStatus: string | null = null;
  bannerActiveTypes = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }
  ]

  viewWidth: string
  scrHeight: number = 0;
  scrWidth: number = 0;
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }

  constructor(
    private router: Router,
    public apiService: ApiService,
    public freezeAppAlertApiService: FreezeAppAlertsApiService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public meta: MetaService,
  ) {
    this.getScreenSize();
  }


  ngOnInit() {

    const paginationDataString = sessionStorage.getItem('paginationDataForFreezeAppAlerts');
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterByActiveStatus = paginationData.filterByActiveStatus;
      this.filterValue = paginationData.filterValue;
    }
    this.filterFreezeAppAlerts();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onFilterChanges()
  }

  onFilterChanges() {
    this.pageIndex = 0;
    this.bOffset = 0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByActiveStatus: this.filterByActiveStatus,
      filterValue: this.filterValue,
    };
    sessionStorage.setItem('paginationDataForFreezeAppAlerts', JSON.stringify(paginationData));
    this.filterFreezeAppAlerts();
  }

  filterFreezeAppAlerts() {

    let queryData: Object = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_active_status: this.filterByActiveStatus || null,
      search_term: this.filterValue || null,
    };

    this.freezeAppAlertApiService.getFreezeAppAlertsByFilter(queryData = queryData).subscribe((resp) => {
      if (resp.status === "success") {
        this.totalCount = resp.data.total_count;
        this.dataSource.data = resp.data.freeze_app_alerts;
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;

    this.filterFreezeAppAlerts();
  }

  viewFreezeAppAlertPopUp(freezeAppAlert: FreezeAppAlertsInterface) {
    if (this.scrWidth < 1450) {
      this.viewWidth = '80%'
    } else {
      this.viewWidth = '50%'
    }

    let dialogRef = this.dialog.open(ViewFreezeAppAlertComponent, {
      data: {
        freezeAppAlertId: freezeAppAlert.id,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }

  createFreezeAppAlert() {
    this.router.navigateByUrl('Admin/freeze-app-alerts/create');
  }

}
