import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { deleteRange } from 'quill/modules/keyboard';

@Component({
  selector: 'app-blood-work-test-create',
  templateUrl: './blood-work-test-create.component.html',
  styleUrls: ['./blood-work-test-create.component.scss']
})
export class BloodWorkTestCreateComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput
    
  pageTitle: string = "Create Blood Work Test"

  public createBloodWorkTestForm: FormGroup;
  public createBloodWorkTestRangeForm: FormGroup;

  isClashingRangesGiven = false;
  errRangesMessage: String = ""

  testRanges = [];

  back(){
    this.router.navigateByUrl('Admin/blood-work-tests');
  }
  
  constructor(
      private _formBuilder: FormBuilder,
      private router: Router, 
      public apiService: ApiService,
      private toastr: ToastrService,
  ){
      // For now we are not implementing anythin related to edit, we will do so later
      this.buildCreateBloodWorkTestForm();
      this.buildCreateBloodWorkTestRangeForm();
  }
    
  ngOnInit() {
  }

  public buildCreateBloodWorkTestForm() {
    this.createBloodWorkTestForm = this._formBuilder.group({
      testName: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(300)]),
      measurementUnits: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
    });
  }

  public buildCreateBloodWorkTestRangeForm() {
    this.createBloodWorkTestRangeForm = this._formBuilder.group({
      // Validators.pattern(/^[0-9]\d*$/)
      rangeMin: new FormControl(null, [Validators.required]),
      rangeMax: new FormControl(null, [Validators.required]),
      rangeMeaning: new FormControl(null, [Validators.required, Validators.maxLength(300)]),
    });
  }

  reset(){
    this.buildCreateBloodWorkTestForm();
    this.buildCreateBloodWorkTestRangeForm()
    this.testRanges = [];
  }

  addTestRange(){
    // validate if given test range is clashing with any
    let rangeMinValue = this.createBloodWorkTestRangeForm.controls.rangeMin.value
    let rangeMaxValue = this.createBloodWorkTestRangeForm.controls.rangeMax.value
    this.isClashingRangesGiven = false;
    if(rangeMinValue > rangeMaxValue){
      this.isClashingRangesGiven = true;
      this.errRangesMessage = "Given min value is greater than max value, please check."
    }
    this.testRanges.forEach((tRange) => {
      if(
        tRange.rangeMinValue <= rangeMinValue && rangeMinValue <= tRange.rangeMaxValue || 
        tRange.rangeMinValue <= rangeMaxValue && rangeMaxValue <= tRange.rangeMaxValue || 
        rangeMinValue <= tRange.rangeMinValue && tRange.rangeMinValue <= rangeMaxValue ||
        rangeMinValue <= tRange.rangeMaxValue && tRange.rangeMaxValue <= rangeMaxValue
      ){
        this.isClashingRangesGiven = true;
        this.errRangesMessage = "Given ranges are clashing with exisitng ones, please check."
      }
    })

    if(!this.isClashingRangesGiven){
      this.testRanges.push({
        rangeMinValue: rangeMinValue,
        rangeMaxValue: rangeMaxValue,
        rangeMeaning: this.createBloodWorkTestRangeForm.controls.rangeMeaning.value,
      })
      const sorterFunc = require('lodash')
      this.testRanges = sorterFunc.orderBy(this.testRanges, ['rangeMinValue'] , ['asc'])
      this.createBloodWorkTestRangeForm.reset();
    }
  }

  deleteTestRange(testRange){
    let giventestRangeIndex = this.testRanges.findIndex(
      (tRange) => tRange.rangeMinValue == testRange.rangeMinValue
    );
    this.testRanges.splice(giventestRangeIndex, 1);
  }

  isBloodWorkTestDataValid(){
    return this.createBloodWorkTestForm.valid  && this.testRanges.length >=1
  }

  createBloodWorkTestAPI(){
    if(this.isBloodWorkTestDataValid()){
      let payload = {
          test_name: this.createBloodWorkTestForm.controls.testName.value,
          description: this.createBloodWorkTestForm.controls.description.value,
          measurement_units: this.createBloodWorkTestForm.controls.measurementUnits.value,
          ranges: this.testRanges.map((tRange) => {
            return {
              range_min_value: tRange.rangeMinValue,
              range_max_value: tRange.rangeMaxValue,
              range_meaning: tRange.rangeMeaning,
            }
          }),
      }
      this.apiService.createBloodWorkTest(payload).subscribe((resp)=>{
        if(resp.status == "success"){
          this.router.navigateByUrl('Admin/blood-work-tests');
        }
      },
      (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }

}
