import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { elementAt, map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
    selector: 'app-create-plan',
    templateUrl: './create-plan.component.html',
    styleUrls: ['./create-plan.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
    ],
})
export class CreatePlanComponent {

    handleStartingSpaceTextInput = handleStartingSpaceTextInput

    planName = null;
    planHierarchy = null;
    planSuitableForCustomers = null;

    PlanTypeList = [];
    PlanSubTypeList = [];
    selectedPlanTypeId = null;
    selectedPlanSubTypeId = null;

    PlanBranchTypesList = [
        {alias: "ALL_BRANCHES", name: "All Branches"},
        {alias: "SPECIFIC_BRANCHES", name: "Specific Branches"},
    ]
    selectedPlanBranchType = null;
    allBranchIdNames = []
    selectedBranchIds = [];

    selectedTrainerRoleIds = [];
    plan_id: string;

    roleList = [];
    AddOnDetails=[];

    planDuration = null;
    planPrice = null;
    sgstTaxPrice = null;
    cgstTaxPrice = null;
    igstTaxPrice = null;

    planDurations = [];

    selectedPlanDurationBranchType = null;
    selectedPlanDurationBranchIds = [];

    plaDurationLastSID = 0;
    plaDurationsErrToAdd = false;
    plaDurationsErrMessage = "";

    allBenefitPoints = [];
    selectedBenefitPoints = [];

    noOfReschedulesPerMonth = null;
    canBookSlots = false;
    selectedSlotBookingDays = [];
    isAllSlotBookingDaysSelected = false;
    selectedPlanSACCode = null;
    PlanSACCodes = []

    allDaysList = [
        { value: 'SUN', viewValue: 'Sunday' },
        { value: 'MON', viewValue: 'Monday' },
        { value: 'TUE', viewValue: 'Tuesday' },
        { value: 'WED', viewValue: 'Wednesday' },
        { value: 'THU', viewValue: 'Thursday' },
        { value: 'FRI', viewValue: 'Friday' },
        { value: 'SAT', viewValue: 'Saturday' }
    
      ]

    isUpdate: boolean = false;
    title: string = 'CREATE PLAN';

    constructor(
        private router: Router,
        private meta: MetaService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public apiService: ApiService,
        public _authService: AuthService,
        private datePipe: DatePipe,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService
    ) {
        // this.route.params.subscribe((params) => {
        //     this.plan_id = params['plan_id'];
        // });
        this.plan_id = sessionStorage.getItem('editPlanId')
    }

    ngOnInit() {
        this.getPlanType();
        this.getRoles();
        this.getSACCodes();
        this.getAllBranchIdNames()

        if (this.plan_id != null) {
            this.getPlanById();
            this.isUpdate = true;
            this.title = "EDIT PLAN"
        }
    }

    back() {
        this.router.navigateByUrl('Admin/plans');
    }

    getPlanType() {
        const data = {
            filter_by_plan_type_active_status: true,
        };
        this.apiService.getPlanTypeByFilter(data).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.PlanTypeList = resp.data.plan_types;
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    getPlanSubType() {
        this.selectedPlanSubTypeId = null;
        this.allBenefitPoints = []
        this.selectedBenefitPoints = [];
        const data = {
            filter_by_plan_sub_type_active_status: true,
            filter_by_plan_type_id: this.selectedPlanTypeId,
        };
        this.apiService.getPlanSubTypeByFilter(data).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.PlanSubTypeList = resp.data.plan_sub_types;
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    getBenefitPointsOfSubType() {
        this.selectedBenefitPoints = [];
        this.allBenefitPoints = []
        let planSubType = this.PlanSubTypeList.find(
            (each) => each.id == this.selectedPlanSubTypeId
        );
        this.allBenefitPoints = planSubType.all_benefits_points;
    }

    getRoles() {
        const data = {
            filter_by_role_active_status: true,
        };
        this.apiService.getRolesByFilter(data).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.roleList = resp.data.roles;
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    getSACCodes() {
        this.apiService.getSACCodes().subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.PlanSACCodes = resp.data.sac_codes;
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    getAllBranchIdNames(){
        let data = { "filter_by_branch_active_status": true }
        this.apiService.getBranchByFilter(data).subscribe((resp) => {
            if (resp.status == "success") {
                this.allBranchIdNames = resp.data.branches
                // this.toastr.success("Branches Loaded successfully");
            }
        },
        (err) => {
            this.toastr.error(err.error.message)
        })
    }

    calculatePlanPrices() {
        if (isNaN(parseFloat(this.planPrice))) {
            this.sgstTaxPrice = null;
            this.cgstTaxPrice = null;
            this.igstTaxPrice = null;
            return
        }
        this.apiService.createCalculatePlansPrice(this.planPrice).subscribe(
            (resp) => {
                if (resp.status == 'success' && this.planPrice) {
                    this.sgstTaxPrice = resp.data.plan_price.sgst_price;
                    this.cgstTaxPrice = resp.data.plan_price.cgst_price;
                    this.igstTaxPrice = resp.data.plan_price.igst_price;
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    allDurationsGivenForAllBranchs: Number[]  = [] // To check if a duration given for all branches is going to be given for any specific branch
    durationMonthsWiseSpecificBranchIds = {}
    addPlanDuration() {

        this.plaDurationsErrToAdd = false;
        this.plaDurationsErrMessage = "";

        // We have validate if the same duration is given for both all_branches and a specific brnach
        if(this.allDurationsGivenForAllBranchs.indexOf(this.planDuration) > -1){
            this.plaDurationsErrToAdd = true;
            this.plaDurationsErrMessage = "This duration is already added for ALL Branches, please check."
        }
        
        if(
            this.selectedPlanDurationBranchType == "ALL_BRANCHES" && 
            Object.keys(this.durationMonthsWiseSpecificBranchIds).includes(this.planDuration.toString())
        ){
            // Just to check if a duration given for any specific branch is goiung to be added for all branches
            this.plaDurationsErrToAdd = true;
            this.plaDurationsErrMessage = "This duration is already added for a Specific Branch, please check."
        }

        // we have to check whether same duration is given for same branch too
        if(this.selectedPlanDurationBranchType == "SPECIFIC_BRANCHES"){
            if(Object.keys(this.durationMonthsWiseSpecificBranchIds).includes(this.planDuration.toString())){
                let duplicateBranchIds = this.durationMonthsWiseSpecificBranchIds[this.planDuration].filter(
                    (branchId) => {
                        return this.selectedPlanDurationBranchIds.includes(branchId)
                    }
                )
                if(duplicateBranchIds.length == 0){
                    this.durationMonthsWiseSpecificBranchIds[this.planDuration].push.apply(
                        this.durationMonthsWiseSpecificBranchIds[this.planDuration], this.selectedPlanDurationBranchIds);
                }else{
                    this.plaDurationsErrToAdd = true;
                    this.plaDurationsErrMessage = "Some/All of the given branches already added for same duration"
                }
            }else{
                this.durationMonthsWiseSpecificBranchIds[this.planDuration] = this.selectedPlanDurationBranchIds;
            }
        }

        if(this.plaDurationsErrToAdd){
            return
        }

        let selectedBranchesNamesList = [];
        let branchTypeName = "All Branches";
        if(this.selectedPlanDurationBranchType == "ALL_BRANCHES"){
            this.allDurationsGivenForAllBranchs.push(this.planDuration);
        }

        if(this.selectedPlanDurationBranchType == "SPECIFIC_BRANCHES"){
            branchTypeName = "Specific Branches";

            this.allBranchIdNames.forEach((branchIdName) => {
                if(this.selectedPlanDurationBranchIds.indexOf(branchIdName.id) > -1){
                    selectedBranchesNamesList.push(branchIdName.name);
                }
            })
        }

        this.plaDurationLastSID += 1;
        this.planDurations.push({
            sid: this.plaDurationLastSID,
            id: null,
            planDuration: this.planDuration,
            planPrice: this.planPrice,
            sgstTaxPrice: this.sgstTaxPrice,
            cgstTaxPrice: this.cgstTaxPrice,
            igstTaxPrice: this.igstTaxPrice,
            branchTypeName: branchTypeName,
            branchType: this.selectedPlanDurationBranchType,
            branchIds: this.selectedPlanDurationBranchIds,
            branchNames: selectedBranchesNamesList
        });

        this.planDuration = null;
        this.planPrice = null;
        this.sgstTaxPrice = null;
        this.cgstTaxPrice = null;
        this.igstTaxPrice = null;
        this.selectedPlanDurationBranchType = null;
        this.selectedPlanDurationBranchIds = [];
    }

    removedPlanDurationIds = []
    deleteplanDuration(planDuration) {
        let givenPlanDurationIndex = this.planDurations.findIndex(
            (pDuration) => pDuration.sid == planDuration.sid
        );
        if(givenPlanDurationIndex > -1){
            if(this.planDurations[givenPlanDurationIndex].id != null){
                this.removedPlanDurationIds.push(this.planDurations[givenPlanDurationIndex].id)
            }
            this.planDurations.splice(givenPlanDurationIndex, 1);
            this.recalculatePlanDurationInfo();
        }
    }

    recalculatePlanDurationInfo(){
        this.allDurationsGivenForAllBranchs = [];
        this.durationMonthsWiseSpecificBranchIds = {};

        this.planDurations.forEach((planDurationObj) => {

            if(planDurationObj.branchType == "ALL_BRANCHES"){
                this.allDurationsGivenForAllBranchs.push(planDurationObj.planDuration.toString());
            }

            if(planDurationObj.branchType == "SPECIFIC_BRANCHES"){
                if(Object.keys(this.durationMonthsWiseSpecificBranchIds).includes(planDurationObj.planDuration)){
                    this.durationMonthsWiseSpecificBranchIds[planDurationObj.planDuration].push.apply(
                        this.durationMonthsWiseSpecificBranchIds[planDurationObj.planDuration], planDurationObj.branchIds);
                }else{
                    this.durationMonthsWiseSpecificBranchIds[planDurationObj.planDuration] = planDurationObj.branchIds;
                }
            }
        })
    }

    CanBookSlotCheckEvent(ob: MatCheckboxChange) {
        this.canBookSlots = ob.checked;
        this.selectedSlotBookingDays = []
        this.noOfReschedulesPerMonth = null
    }

    someComplete(): boolean {
        if (this.selectedSlotBookingDays.length == this.allDaysList.length) {
          this.isAllSlotBookingDaysSelected = true
        } else {
          this.isAllSlotBookingDaysSelected = false
        }
    
        return this.selectedSlotBookingDays.length > 0 && !this.isAllSlotBookingDaysSelected;
      }
      setAll(change) {
        if (change.checked) {
          this.isAllSlotBookingDaysSelected = true;
          this.selectedSlotBookingDays = this.allDaysList.map(e => e.value);
        } else {
          this.isAllSlotBookingDaysSelected = false;
          this.selectedSlotBookingDays = [];
        }
        this.selectedDays(this.selectedSlotBookingDays)
      }
      selectedDays(event) {
        let selectedDayValues = [...event];
        if (selectedDayValues.length >= 7) {
          this.isAllSlotBookingDaysSelected = true
        } else {
          this.isAllSlotBookingDaysSelected = false
        }
        this.selectedSlotBookingDays = []
        selectedDayValues.forEach(selectedDayValue => {
          let selectedObj = this.allDaysList.find(each => each.value === selectedDayValue)
          this.selectedSlotBookingDays.push(selectedObj.value);
        });
      }

    createPlan() {
        let requestDataPlanDurations = [];
        this.planDurations.forEach((element) => {
            requestDataPlanDurations.push({
                validity_in_months: element.planDuration,
                price: element.planPrice,
                applicable_to_branches_type: element.branchType,
                applicable_to_branch_ids: element.branchIds
            });
        });

        let requestData = {
            plan: {
                name: this.planName,
                benefits: this.selectedBenefitPoints,
                schedule_role_ids: this.selectedTrainerRoleIds,
                plan_hierarchy: this.planHierarchy,
                suitable_for_customers: this.planSuitableForCustomers,
                start_datetime: null,
                end_datetime: null,
                no_of_reschedules_per_month: this.noOfReschedulesPerMonth == null ? 0: this.noOfReschedulesPerMonth,
                plan_sub_type_id: this.selectedPlanSubTypeId,
                can_book_slots: this.canBookSlots,
                slot_booking_days: this.selectedSlotBookingDays,
                sac_code: this.selectedPlanSACCode,
                applicable_to_branches_type: this.selectedPlanBranchType,
                applicable_to_branch_ids: this.selectedBranchIds
          },
          plan_durations: requestDataPlanDurations,
        };

        this.apiService.createPlan(requestData).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.router.navigateByUrl('Admin/plans');
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    reset() {
        this.planName = null;
        this.planHierarchy = null;
        this.planSuitableForCustomers = null;

        this.PlanSubTypeList = [];
        this.selectedPlanTypeId = null;
        this.selectedPlanSubTypeId = null;

        this.selectedTrainerRoleIds = [];

        this.planDuration = null;
        this.planPrice = null;
        this.sgstTaxPrice = null;
        this.cgstTaxPrice = null;
        this.igstTaxPrice = null;

        this.planDurations = [];

        this.allBenefitPoints = [];
        this.selectedBenefitPoints = [];

        this.noOfReschedulesPerMonth = null;
        this.selectedSlotBookingDays = [];
        this.canBookSlots = false;
        this.selectedPlanSACCode = null;
        this.selectedPlanBranchType = null;
        this.selectedBranchIds = [];

        this.selectedPlanDurationBranchType = null;
        this.selectedPlanDurationBranchIds = [];

        this.plaDurationsErrToAdd = false;
        this.plaDurationsErrMessage = "";
        this.allDurationsGivenForAllBranchs = [];
        this.durationMonthsWiseSpecificBranchIds = {};
        this.removedPlanDurationIds = [];
    }

    getAllActiveBranchIdsFromGivenBranches(
        branchIdNames, activelyExisitngBranchIds, activelyExisitngBranchNames
    ){
        let givenBranchIds = []
        branchIdNames.forEach(branchIdName => {
            givenBranchIds.push(branchIdName.id)
        });

        this.allBranchIdNames.forEach(branchIdName => {
            if(givenBranchIds.includes(branchIdName.id)){
                activelyExisitngBranchIds.push(branchIdName.id);
                activelyExisitngBranchNames.push(branchIdName.name);
            }
        })

    }

    getPlanById() {
        this.AddOnDetails = [];
        this.apiService.getPlansById(this.plan_id).subscribe((resp) => {
            if (resp.status == "success") {
                this.AddOnDetails.push(resp.data.plan);
                this.planHierarchy = resp.data.plan.plan_hierarchy;
                this.canBookSlots = resp.data.plan.can_book_slots;
                this.planName = resp.data.plan.name;
                this.selectedPlanSACCode = resp.data.plan.sac_code;

                this.selectedPlanBranchType = resp.data.plan.applicable_to_branches_type;
                // from the given already selected branches, we are just going to show the active branches as selected and when they click on update the deactivated branches will also be removed.
                this.getAllActiveBranchIdsFromGivenBranches(resp.data.plan.applicable_to_branch_id_names, this.selectedBranchIds, [])

                resp.data.plan.plan_durations.map(elem => {
                    if (!elem.is_removed) {

                        let planDurationBranchIds = []
                        let planDurationBranchNames = []
                        this.getAllActiveBranchIdsFromGivenBranches(
                            elem["applicable_to_branch_id_names"], planDurationBranchIds, planDurationBranchNames)

                        let branchTypeName = ""
                        this.PlanBranchTypesList.forEach(branchType => {
                            if(branchType.alias == elem["applicable_to_branches_type"]){
                                branchTypeName = branchType.name
                            }
                        })
                        this.plaDurationLastSID += 1;
                        this.planDurations.push({
                            sid: this.plaDurationLastSID,
                            id: elem["id"],
                            planDuration: elem["validity_in_months"],
                            planPrice: elem["price"],
                            sgstTaxPrice: elem["sgst_tax_price"],
                            cgstTaxPrice: elem["cgst_tax_price"],
                            igstTaxPrice: elem["igst_tax_price"],
                            totalPlanPrice: elem["total_price"],
                            branchTypeName: branchTypeName,
                            branchType: elem["applicable_to_branches_type"],
                            branchIds: planDurationBranchIds,
                            branchNames: planDurationBranchNames,
                        })
                    }
                });
                this.recalculatePlanDurationInfo();
                this.selectedPlanTypeId = resp.data.plan.plan_type.id
                this.getPlanSubType()
                this.selectedPlanSubTypeId = resp.data.plan.plan_sub_type.id
                this.selectedTrainerRoleIds = resp.data.plan.schedule_role_ids
                if(resp.data.plan.suitable_for_customers != "{}"){
                this.planSuitableForCustomers = resp.data.plan.suitable_for_customers
                }else{
                    this.planSuitableForCustomers = "-"
                }
                this.noOfReschedulesPerMonth = resp.data.plan.no_of_reschedules_per_month
                this.allBenefitPoints = [...resp.data.plan.benefits]
                this.selectedBenefitPoints = this.allBenefitPoints
                this.selectedTrainerRoleIds = resp.data.plan.schedule_roles.map((each) => each.id)
                this.allDaysList.forEach((eachDay) => {
                if (resp.data.plan.slot_booking_days.includes(eachDay.value)){
                    this.selectedSlotBookingDays.push(eachDay.value)
                }
                })
                if (this.selectedSlotBookingDays.length == 7){
                this.isAllSlotBookingDaysSelected = true
                }else{
                this.isAllSlotBookingDaysSelected = false
                }
            }
        },
          (err) => {
            this.toastr.error(err.error.message)
          })
      }


      updatePlan() {
        let requestDataPlanDurations = [];
        this.planDurations.forEach((element) => {
            if(element.id == null){
                requestDataPlanDurations.push({
                    validity_in_months: element.planDuration,
                    price: element.planPrice,
                    applicable_to_branches_type: element.branchType,
                    applicable_to_branch_ids: element.branchIds,
                });
            }
        });

        let requestData = {
            plan: {
                name: this.planName,
                benefits: this.selectedBenefitPoints,
                schedule_role_ids: this.selectedTrainerRoleIds,
                plan_hierarchy: this.planHierarchy,
                suitable_for_customers: this.planSuitableForCustomers,
                start_datetime: null,
                end_datetime: null,
                no_of_reschedules_per_month: this.noOfReschedulesPerMonth == null? 0:this.noOfReschedulesPerMonth,
                plan_sub_type_id: this.selectedPlanSubTypeId,
                can_book_slots: this.canBookSlots,
                slot_booking_days: this.selectedSlotBookingDays,
                sac_code: this.selectedPlanSACCode,
                applicable_to_branches_type: this.selectedPlanBranchType,
                applicable_to_branch_ids: this.selectedBranchIds
          },
          creatable_plan_durations: requestDataPlanDurations,
          removable_plan_duration_ids: this.removedPlanDurationIds,
        };

        this.apiService.updatePlan(requestData, this.plan_id).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.router.navigateByUrl('Admin/plans');
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }

    someBranchTypeSelectedForPlan(){
        this.selectedBranchIds = [];
    }

    someBranchTypeSelectedForPlanDuration(){
        this.selectedPlanDurationBranchIds = [];
    }

}
