<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
                <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                    Blood&nbsp;Work&nbsp;Tests
                </h1>
            </div>
            
            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToBloodWorkTestCreate">
              <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
              (click)="createBloodWorkTest()">
              <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1 txt" style="color: white">Create Blood Work Test</span>
            </button>
          </div>
        </div>
        
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)"  [autocomplete]="'off'" [placeholder]="'Search TestName'" [(ngModel)]="filterValue">
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2" >
              <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All'" [(ngModel)]="selectedActiveType" (selectionChange)="filterBloodWorkTests()">
                      <mat-option  value="">All</mat-option>
                      <mat-option *ngFor="let activeType of activeTypes" [value]="activeType.value">{{activeType.viewValue}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
    </div>
  
    <div class="mat-elevation-z8 mt-2 overflow-auto">
      <table mat-table [dataSource]="dataSource">
  
        <ng-container matColumnDef="testName">
          <th mat-header-cell *matHeaderCellDef>Test Name</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.test_name}} </td>
        </ng-container>
  
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.description}} </td>
        </ng-container>
  
        <ng-container matColumnDef="measurementUnits">
          <th mat-header-cell *matHeaderCellDef >Measurement Units</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.measurement_units}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Created By</th>
          <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
              {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span> 
          </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef >Created Date</th>
          <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_at | dateTimeFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th class="" mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
              <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
                svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="viewBloodWorkTest(row)">View</button>
                <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToTestDeactivate" (click)="verifyDeactivate(row.id)">Deactivate</button>
                <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToTestReactivate" (click)="reactivateTest(row.id)">Reactivate</button>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <div>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">
              <h3 style="display: flex;justify-content: center; margin:10%;">
                No Records Found
              </h3>
            </td>
          </tr>
        </div>
      </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons>
    </mat-paginator>
</div>