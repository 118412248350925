import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ViewBloodWorkTestComponent } from '../view-blood-work-test/view-blood-work-test.component';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { featureConstants, MetaService } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';


interface BloodWorkTestElement {
  is_removed: boolean;
  id: String;
  test_name: string;
  description: string;
  measurement_units: string;
  created_by: Object,
  created_at: string,
}

@Component({
  selector: 'app-blood-work-tests-list',
  templateUrl: './blood-work-tests-list.component.html',
  styleUrls: ['./blood-work-tests-list.component.scss']
})
export class BloodWorkTestsListComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<BloodWorkTestElement>();

  searchInputControl: UntypedFormControl = new UntypedFormControl();

  displayedColumns = ['testName', 'description', 'measurementUnits', 'createdBy', 'createdDate', 'Actions'];

  filterValue: string = ""
  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit: number = 10;

  activeTypes = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }
  ]
  selectedActiveType = null;


  viewWidth: string
  scrHeight: number = 0;
  scrWidth: number = 0;
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
 
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToBloodWorkTestCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_BLOOD_WORK_TESTS) > -1
  isUserAllowedToTestDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_BLOOD_WORK_TESTS) > -1
  isUserAllowedToTestReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_BLOOD_WORK_TESTS) > -1

  constructor(
    private router: Router,
    public apiService: ApiService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public meta: MetaService,
  ){
    this.getScreenSize();
  }

  ngOnInit() {
    this.filterBloodWorkTests();
  }

  createBloodWorkTest(){
    this.router.navigateByUrl('Admin/create-blood-work-test');
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value; 
    this.filterBloodWorkTests()
  }

  onPaginateChange(event: PageEvent): void {
  
      this.bLimit = event.pageSize;
      this.bOffset = (event.pageIndex) * event.pageSize;
      this.pageIndex = event.pageIndex;
    
      this.filterBloodWorkTests(); 
    }

  filterBloodWorkTests(){
    let queryData: Object = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_search_term: this.filterValue || null,
      filter_by_active_status: this.selectedActiveType || null,
    }

    this.apiService.getBloodWorkTestsByFilter(queryData=queryData).subscribe((resp) => {
      if (resp.status === "success") {
        this.totalCount = resp.data.total_count;  
        this.dataSource.data = resp.data.bloodwork_tests;
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
  }

  viewBloodWorkTest(row){
    let bloodWorkTestInfo = [];
    this.apiService.getBloodWorkTestById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        bloodWorkTestInfo.push(resp.data.bloodwork_test);
        this.viewBloodWorkTestPopUp(bloodWorkTestInfo)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  viewBloodWorkTestPopUp(bloodWorkTestInfo) {
    if(this.scrWidth < 1450){
      this.viewWidth='100%'
    }else{
      this.viewWidth='60%'
    }
    let dialogRef = this.dialog.open(ViewBloodWorkTestComponent, {
      data: {
        bloodWorkTestInfo: bloodWorkTestInfo,
      },
      // height: auto,
      width: this.viewWidth,
      
    });
  }

  verifyDeactivate(testId){

    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
            "message": "The stats related to this test won't be shown on mobile to any user."
        }, // Pass data to the dialog
        width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {
    
      if (this.meta.dialogClosed) {
        this.deactivateTest(testId=testId)
      }
    });
  }

  deactivateTest(testId: String) {
    this.apiService.deactivateBloodWorkTest(testId=testId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          test => {
            if (test.id == testId)
              test.is_removed = true
          }
        )
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  reactivateTest(testId){
    this.apiService.reactivateBloodWorkTest(testId=testId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          test => {
            if (test.id == testId)
              test.is_removed = false
          }
        )
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
    })
  }

}
