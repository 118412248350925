<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW PLAN</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">
            <div class="card dataCard" *ngFor="let val of bloodWorkTestInfo">
                <div class="mt-2 ml-3 ">
                    <h1 class="headings">
                        Details
                    </h1>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Test Name:
                            </div>
                            <div class="fw-bold">
                                {{val.test_name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Description:
                            </div>
                            <div class="fw-bold">
                               {{val.description}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Measure Units:
                            </div>
                            <div class="fw-bold">
                                {{val.measurement_units}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <div class="listFont fw-bold">
                                Created By:
                            </div>
                            <div class="fw-bold">
                                {{val.created_by.full_name}}<span *ngIf="val.created_by.role">({{val.created_by.role.name}})</span>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <div class="listFont fw-bold">
                                Created Date:
                            </div>
                            <div class="fw-bold">
                                {{val.created_at | dateFormat }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Blood Work Test Ranges
                    </h4>
                    <div class="col-sm-12 col-md-6 d-flex flex-column  m-auto" style="max-height: 450px; overflow-y: scroll;">
                        <table class="table table-responsive" style="width: 90%">
                            <thead class="thead-dark" style="background: #000; color: #fff; position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">Range Min Value</th>
                                    <th scope="col">Range Max Value</th>
                                    <th scope="col">Range Menaing</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tRange of testRanges" [ngClass]="{'delete-records-style': false}">
                                    <td>{{ tRange.range_min_value}}</td>
                                    <td>{{ tRange.range_max_value }}</td>
                                    <td>{{ tRange.range_meaning }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>