import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { featureConstants, MetaService } from 'app/meta.service';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

interface BloodWorkStatElement {
  is_removed: boolean;
  id: String;
  test_name: string;
  test_value: string;
  test_meaning: string;
  collected_at: string;
  user: Object;
  description: string;
  measurement_units: string;
  created_by: Object;
  created_at: string;
}

@Component({
  selector: 'app-blood-work-list',
  templateUrl: './blood-work-list.component.html',
  styleUrls: ['./blood-work-list.component.scss']
})
export class BloodWorkListComponent {
  
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['userName', 'testName', 'testValue', 'testMeaning', 'collectedDate', 'createdBy', 'Actions'];
  dataSource = new MatTableDataSource<BloodWorkStatElement>();

  filterValue=null;
  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToBloodWorkStatCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_BLOOD_WORK_STATS) > -1
  isUserAllowedToStatDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_BLOOD_WORK_STATS) > -1
  isUserAllowedToStatReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_BLOOD_WORK_STATS) > -1

  constructor(
    private router: Router, 
    public dialog: MatDialog,
    public meta: MetaService, 
    public apiService: ApiService,
    private toastr: ToastrService
  ){

  }

  ngOnInit() {
    this.filterBloodWorkStats()
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.filterBloodWorkStats()
  }

  onPaginateChange(event: PageEvent): void {
      this.bLimit = event.pageSize;
      this.bOffset = (event.pageIndex) * event.pageSize;
      this.pageIndex = event.pageIndex;
    
      this.filterBloodWorkStats(); 
    }

  filterBloodWorkStats(){

    let filterData = {
      filter_by_search_term: this.filterValue || null,
      limit: this.bLimit,
      offset: this.bOffset,
    }

    this.apiService.getAllBloodWorkStats(filterData).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.bloodwork_stats;
        this.totalCount = resp.data.total_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }

  createBloodWorkStat(){
    this.router.navigateByUrl('Admin/create-blood-work-stat');
  }

  verifyDeactivate(statId){
  
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
            "message": "Stat won't be considered to show on mobile or in any other calculation."
        }, // Pass data to the dialog
        width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {
    
      if (this.meta.dialogClosed) {
        this.deactivateStat(statId=statId)
      }
    });
  }
  
  deactivateStat(statId: String) {
    this.apiService.deactivateBloodWorkStat(statId=statId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          stat => {
            if (stat.id == statId)
              stat.is_removed = true
          }
        )
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  
  reactivateStat(statId){
    this.apiService.reactivateBloodWorkStat(statId=statId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          stat => {
            if (stat.id == statId)
              stat.is_removed = false
          }
        )
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
    })
  }
}
